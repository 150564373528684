// import React from "react";
// import {
//    useTable,
//    usePagination,
//    useSortBy,
//    useGlobalFilter,
// } from "react-table";

// const Table = ({
//    columns,
//    data,
//    numberOptions,
//    dataFun,
//    globalFilter,
//    setGlobalFilter,
// }) => {
//    const {
//       getTableProps,
//       getTableBodyProps,
//       headerGroups,
//       page,
//       nextPage,
//       previousPage,
//       prepareRow,
//       state: { pageIndex, pageSize },
//       setPageSize,
//       setGlobalFilter: setLocalGlobalFilter,
//    } = useTable(
//       {
//          columns,
//          data,
//          initialState: { pageIndex: 0, pageSize: 10, globalFilter },
//       },
//       useGlobalFilter,
//       useSortBy,
//       usePagination
//    );

//    // Sync local global filter with the prop
//    React.useEffect(() => {
//       setLocalGlobalFilter(globalFilter);
//    }, [globalFilter, setLocalGlobalFilter]);

//    return (
//       <>
//          <div className="w-full h-full sm:px-2 md:px-3 flex flex-col">
//             <div className="w-full h-full flex md:overflow-x-hidden sm:overflow-x-scroll">
//                <div className="w-full">
//                   <table {...getTableProps()} className="w-full">
//                      <thead className="bg-primary text-white">
//                         {headerGroups.map((headerGroup) => {
//                            const { key, ...restHeaderGroupProps } =
//                               headerGroup.getHeaderGroupProps();
//                            return (
//                               <tr key={key} {...restHeaderGroupProps}>
//                                  {headerGroup.headers.map((column) => {
//                                     const { key, ...restColumnProps } =
//                                        column.getHeaderProps(
//                                           column.getSortByToggleProps()
//                                        );
//                                     return (
//                                        <th
//                                           key={key}
//                                           className="sm:p-[2px] md:p-3 sm:text-[2.5vw] md:text-[1vw]"
//                                           {...restColumnProps}
//                                        >
//                                           {column.render("Header")}
//                                        </th>
//                                     );
//                                  })}
//                               </tr>
//                            );
//                         })}
//                      </thead>
//                      <tbody {...getTableBodyProps()}>
//                         {page.map((row, index) => {
//                            prepareRow(row);
//                            const { key, ...restRowProps } = row.getRowProps();
//                            const globalIndex = pageIndex * pageSize + index;
//                            return (
//                               <tr
//                                  key={key}
//                                  onClick={() => dataFun(globalIndex)}
//                                  className="hover:bg-gray-300 hover:cursor-pointer"
//                                  {...restRowProps}
//                               >
//                                  {row.cells.map((cell) => {
//                                     const { key, ...restCellProps } =
//                                        cell.getCellProps();
//                                     return (
//                                        <td
//                                           key={key}
//                                           className="text-center sm:p-[2px] md:p-3 sm:text-[2.5vw] md:text-[1vw]"
//                                           {...restCellProps}
//                                        >
//                                           {cell.render("Cell")}
//                                        </td>
//                                     );
//                                  })}
//                               </tr>
//                            );
//                         })}
//                      </tbody>
//                   </table>
//                </div>
//                <br />
//             </div>
//             <div className="flex justify-center items-center gap-x-[1vw] pb-10">
//                <span className="text-center sm:p-[2px] md:p-3 sm:text-[3vw] sm-md:text-[2.8vw] px6:text-[3vw] px8:text-[2.3vw] md:text-[1vw]">
//                   Page{" "}
//                   <strong>
//                      {pageIndex + 1} of {Math.ceil(data.length / pageSize)}
//                   </strong>
//                </span>
//                <select
//                   className="text-center sm:p-[2px] md:p-3 sm:text-[3vw] sm-md:text-[2.8vw] px6:text-[3vw] px8:text-[2.3vw] md:text-[1vw]"
//                   value={pageSize}
//                   onChange={(e) => {
//                      const newSize = Number(e.target.value);
//                      setPageSize(newSize);
//                   }}
//                >
//                   {numberOptions.map((size) => (
//                      <option
//                         key={size}
//                         value={size}
//                         className="text-center sm:p-[2px] md:p-3 sm-text-[3vw] sm-md-text-[2.8vw] px6-text-[3vw] px8-text-[2.3vw] md-text-[1vw]"
//                      >
//                         {size}
//                      </option>
//                   ))}
//                </select>
//                <button
//                   onClick={() => previousPage()}
//                   className="border-gray-900 hover:bg-primary bg-gray-300 rounded-lg text-center sm-text-[2.5vw] md-text-[1vw] px-3 w-24 shadow-sm"
//                >
//                   Previous
//                </button>
//                <button
//                   onClick={() => nextPage()}
//                   className="border-gray-900 hover:bg-primary bg-gray-300 rounded-lg text-center sm-text-[2.5vw] md-text-[1vw] w-24 shadow-sm px-3"
//                >
//                   Next
//                </button>
//             </div>
//          </div>
//       </>
//    );
// };

// export default Table;

//----------------------------------------------------------------------------------------------------------------------------------

import React from "react";
import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
} from "react-table";

const Table = ({
  columns,
  data,
  numberOptions,
  dataFun,
  globalFilter,
  setGlobalFilter,
}) => {
  // Add original index to each row in the data
  const dataWithOriginalIndex = React.useMemo(
    () => data.map((row, index) => ({ ...row, originalIndex: index })),
    [data]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    prepareRow,
    state: { pageIndex, pageSize },
    setPageSize,
    setGlobalFilter: setLocalGlobalFilter,
  } = useTable(
    {
      columns,
      // data,
      data: dataWithOriginalIndex, // Pass the modified data with original index
      initialState: { pageIndex: 0, pageSize: 10, globalFilter },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  // Sync local global filter with the prop
  React.useEffect(() => {
    setLocalGlobalFilter(globalFilter);
  }, [globalFilter, setLocalGlobalFilter]);

  return (
    <>
      <div className="w-full h-full sm:px-2 md:px-3 flex flex-col">
        <div className="w-full h-full flex md:overflow-x-hidden sm:overflow-x-scroll">
          <div className="w-full">
            <table {...getTableProps()} className="w-full">
              <thead className="bg-primary text-white">
                {headerGroups.map((headerGroup) => {
                  const { key, ...restHeaderGroupProps } =
                    headerGroup.getHeaderGroupProps();
                  return (
                    <tr key={key} {...restHeaderGroupProps}>
                      {headerGroup.headers.map((column) => {
                        const { key, ...restColumnProps } =
                          column.getHeaderProps(column.getSortByToggleProps());
                        return (
                          <th
                            key={key}
                            className="sm:p-[2px] md:p-3 sm:text-[2.5vw] md:text-[1vw]"
                            {...restColumnProps}
                          >
                            {column.render("Header")}
                          </th>
                        );
                      })}
                    </tr>
                  );
                })}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row, index) => {
                  prepareRow(row);
                  const { key, ...restRowProps } = row.getRowProps();
                  // Since we added originalIndex to each row, we can use that
                  const originalIndex = row.original.originalIndex;
                  // const globalIndex = pageIndex * pageSize + index;
                  return (
                    <tr
                      key={key}
                      onClick={() => dataFun(originalIndex)}
                      // onClick={() => dataFun(globalIndex)}
                      className="hover:bg-gray-300 hover:cursor-pointer"
                      {...restRowProps}
                    >
                      {row.cells.map((cell) => {
                        const { key, ...restCellProps } = cell.getCellProps();
                        return (
                          <td
                            key={key}
                            className="text-center sm:p-[2px] md:p-3 sm:text-[2.5vw] md:text-[1vw]"
                            {...restCellProps}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <br />
        </div>
        <div className="flex justify-center items-center gap-x-[1vw] pb-10">
          <span className="text-center sm:p-[2px] md:p-3 sm:text-[3vw] sm-md:text-[2.8vw] px6:text-[3vw] px8:text-[2.3vw] md:text-[1vw]">
            Page{" "}
            <strong>
              {pageIndex + 1} of {Math.ceil(data.length / pageSize)}
            </strong>
          </span>
          <select
            className="text-center sm:p-[2px] md:p-3 sm:text-[3vw] sm-md:text-[2.8vw] px6:text-[3vw] px8:text-[2.3vw] md:text-[1vw]"
            value={pageSize}
            onChange={(e) => {
              const newSize = Number(e.target.value);
              setPageSize(newSize);
            }}
          >
            {numberOptions.map((size) => (
              <option
                key={size}
                value={size}
                className="text-center sm:p-[2px] md:p-3 sm-text-[3vw] sm-md-text-[2.8vw] px6-text-[3vw] px8-text-[2.3vw] md-text-[1vw]"
              >
                {size}
              </option>
            ))}
          </select>
          <button
            onClick={() => previousPage()}
            className="border-gray-900 hover:bg-primary bg-gray-300 rounded-lg text-center sm-text-[2.5vw] md-text-[1vw] px-3 w-24 shadow-sm"
          >
            Previous
          </button>
          <button
            onClick={() => nextPage()}
            className="border-gray-900 hover:bg-primary bg-gray-300 rounded-lg text-center sm-text-[2.5vw] md-text-[1vw] w-24 shadow-sm px-3"
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default Table;
