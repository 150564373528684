import axios from "axios";

import { URL } from "../../utils/URL";
import { REGIONS, REGIONS_ERROR, LOGOUT } from "./types";

export const loadRegions = () => async (dispatch) => {
  try {
    const response = await axios.get(URL + "/api/regions/");

    dispatch({ type: REGIONS, payload: response.data });
  } catch (error) {
    if (error.response) {
      if (error.response.data.message === "Invalid Authentication") {
        console.log(error.response.data.message);
        dispatch({
          type: LOGOUT,
          payload: error.response.data,
        });
      }
      dispatch({
        type: REGIONS_ERROR,
        payload: {
          msg: error.response.data.message,
          status: error.response.status,
        },
      });
    } else {
      dispatch({
        type: REGIONS_ERROR,
        payload: {
          msg: error.message,
          status: error.status,
        },
      });
    }
  }
};

export const addRegion =
  (toast, setLoad, name, navigate) => async (dispatch) => {
    try {
      const body = JSON.stringify({ name });
      const config = {
        headers: {
          "Content-Type": "Application/json",
        },
      };

      const res = await axios.post(URL + `/api/regions/`, body, config);

      dispatch({
        type: REGIONS,
        payload: res.data,
      });
      setLoad(false);
      toast.success("Region added Successfully", {
        autoClose: 5000,
      });

      navigate("/regions");
    } catch (e) {
      setLoad(false);
      console.log(e);
      toast.error(e.response.data.message, {
        autoClose: 5000,
      });
      dispatch({
        type: REGIONS_ERROR,
        payload: e.response.data,
      });
      if (e.response.data.message === "Invalid Authentication") {
        console.log(e.response.data.message);
        dispatch({
          type: LOGOUT,
          payload: e.response.data,
        });
      }
    }
  };

export const assignManager =
  (toast, setLoad, id, role, userId, navigate) => async (dispatch) => {
    try {
      const body = JSON.stringify({ role, userId });
      const config = {
        headers: {
          "Content-Type": "Application/json",
        },
      };

      const res = await axios.put(URL + `/api/regions/${id}`, body, config);

      dispatch({
        type: REGIONS,
        payload: res.data,
      });
      setLoad(false);
      toast.success("Region Updated Successfully", {
        autoClose: 5000,
      });

      navigate("/regions");
    } catch (e) {
      setLoad(false);
      console.log(e);
      toast.error(e.response.data.message, {
        autoClose: 5000,
      });
      dispatch({
        type: REGIONS_ERROR,
        payload: e.response.data,
      });
      if (e.response.data.message === "Invalid Authentication") {
        console.log(e.response.data.message);
        dispatch({
          type: LOGOUT,
          payload: e.response.data,
        });
      }
    }
  };

export const deleteRegion =
  (toast, setLoad, id, navigate) => async (dispatch) => {
    try {
      const confirm = window.confirm(
        "Are you sure you want to remove this region permanently?"
      );
      if (confirm) {
        const res = await axios.delete(URL + `/api/regions/${id}`);
        setLoad(false);
        toast.success("Region Deleted Successfully", {
          autoClose: 5000,
        });
        dispatch({
          type: REGIONS,
          payload: res.data,
        });
        navigate("/regions");
      }
    } catch (error) {
      setLoad(false);
      toast.error(error.response.data.message, {
        autoClose: 5000,
      });
      dispatch({
        type: REGIONS_ERROR,
        payload: {
          msg: error.response.data.message,
          status: error.response.status,
        },
      });
      if (error.response.data.message === "Invalid Authentication") {
        console.log(error.response.data.message);
        dispatch({
          type: LOGOUT,
          payload: error.response.data,
        });
      }
    }
  };
