import axios from "axios";

import { URL } from "../../utils/URL";
import {
  TICKETS,
  TICKETS_OPEN,
  TICKETS_CLOSED,
  TICKETS_ESCALATED,
  TICKETS_INIT_STATE,
  TICKETS_ERROR,
  LOGOUT,
} from "./types";

export const loadTickets = () => async (dispatch) => {
  try {
    const response = await axios.get(URL + "/api/tickets/");

    dispatch({ type: TICKETS, payload: response.data });
  } catch (error) {
    if (error.response) {
      if (error.response.data.message === "Invalid Authentication") {
        console.log(error.response.data.message);
        dispatch({
          type: LOGOUT,
          payload: error.response.data,
        });
      }
      dispatch({
        type: TICKETS_ERROR,
        payload: {
          msg: error.response.data.message,
          status: error.response.status,
        },
      });
    } else {
      dispatch({
        type: TICKETS_ERROR,
        payload: {
          msg: error.message,
          status: error.status,
        },
      });
    }
  }
};

export const loadTicketsU = () => async (dispatch) => {
  try {
    const response = await axios.get(URL + "/api/tickets/endUser/mine/all/");

    dispatch({ type: TICKETS, payload: response.data });
  } catch (error) {
    dispatch({
      type: TICKETS_ERROR,
      payload: {
        msg: error.response.data.message,
        status: error.response.status,
      },
    });
    if (error.response.data.message === "Invalid Authentication") {
      console.log(error.response.data.message);
      dispatch({
        type: LOGOUT,
        payload: error.response.data,
      });
    }
  }
};

export const loadOpenTicketsU = () => async (dispatch) => {
  try {
    const response = await axios.get(URL + "/api/tickets/endUser/mine/open/");

    dispatch({ type: TICKETS_OPEN, payload: response.data });
  } catch (error) {
    dispatch({
      type: TICKETS_ERROR,
      payload: {
        msg: error.response.data.message,
        status: error.response.status,
      },
    });
    if (error.response.data.message === "Invalid Authentication") {
      console.log(error.response.data.message);
      dispatch({
        type: LOGOUT,
        payload: error.response.data,
      });
    }
  }
};

export const loadClosedTicketsU = () => async (dispatch) => {
  try {
    const response = await axios.get(URL + "/api/tickets/endUser/mine/closed/");

    dispatch({ type: TICKETS_CLOSED, payload: response.data });
  } catch (error) {
    dispatch({
      type: TICKETS_ERROR,
      payload: {
        msg: error.response.data.message,
        status: error.response.status,
      },
    });
    if (error.response.data.message === "Invalid Authentication") {
      console.log(error.response.data.message);
      dispatch({
        type: LOGOUT,
        payload: error.response.data,
      });
    }
  }
};

export const loadTicketsDep = () => async (dispatch) => {
  try {
    const response = await axios.get(URL + "/api/tickets/dep/mine/all/");

    dispatch({ type: TICKETS, payload: response.data });
  } catch (error) {
    dispatch({
      type: TICKETS_ERROR,
      payload: {
        msg: error.response.data.message,
        status: error.response.status,
      },
    });
    if (error.response.data.message === "Invalid Authentication") {
      console.log(error.response.data.message);
      dispatch({
        type: LOGOUT,
        payload: error.response.data,
      });
    }
  }
};

export const loadUnassignedTickets = () => async (dispatch) => {
  try {
    const response = await axios.get(URL + "/api/tickets/dep/mine/unassigned/");

    dispatch({ type: TICKETS, payload: response.data });
  } catch (error) {
    if (error.response) {
      if (error.response.data.message === "Invalid Authentication") {
        console.log(error.response.data.message);
        dispatch({
          type: LOGOUT,
          payload: error.response.data,
        });
      }
      dispatch({
        type: TICKETS_ERROR,
        payload: {
          msg: error.response.data.message,
          status: error.response.status,
        },
      });
    } else {
      dispatch({
        type: TICKETS_ERROR,
        payload: {
          msg: error.message,
          status: error.status,
        },
      });
    }
  }
};

export const loadOpenTicketsDep = () => async (dispatch) => {
  try {
    const response = await axios.get(URL + "/api/tickets/dep/mine/open/");

    dispatch({ type: TICKETS_OPEN, payload: response.data });
  } catch (error) {
    console.log(error.message);
    dispatch({
      type: TICKETS_ERROR,
      payload: {
        msg: error.response.data.message,
        status: error.response.status,
      },
    });
    if (error.response.data.message === "Invalid Authentication") {
      console.log(error.response.data.message);
      dispatch({
        type: LOGOUT,
        payload: error.response.data,
      });
    }
  }
};

export const loadClosedTicketsDep = () => async (dispatch) => {
  try {
    const response = await axios.get(URL + "/api/tickets/dep/mine/closed/");

    dispatch({ type: TICKETS_CLOSED, payload: response.data });
  } catch (error) {
    dispatch({
      type: TICKETS_ERROR,
      payload: {
        msg: error.response.data.message,
        status: error.response.status,
      },
    });
    if (error.response.data.message === "Invalid Authentication") {
      console.log(error.response.data.message);
      dispatch({
        type: LOGOUT,
        payload: error.response.data,
      });
    }
  }
};

export const loadMyEscalated = () => async (dispatch) => {
  try {
    const response = await axios.get(URL + "/api/tickets/escalated/mine/");

    dispatch({ type: TICKETS_ESCALATED, payload: response.data });
  } catch (error) {
    if (error.response) {
      if (error.response.data.message === "Invalid Authentication") {
        console.log(error.response.data.message);
        dispatch({
          type: LOGOUT,
          payload: error.response.data,
        });
      }
      dispatch({
        type: TICKETS_ERROR,
        payload: {
          msg: error.response.data.message,
          status: error.response.status,
        },
      });
    } else {
      dispatch({
        type: TICKETS_ERROR,
        payload: {
          msg: error.message,
          status: error.status,
        },
      });
    }
  }
};

export const loadOpenTicketsEmp = () => async (dispatch) => {
  try {
    const response = await axios.get(URL + "/api/tickets/emp/mine/open/");

    dispatch({ type: TICKETS_OPEN, payload: response.data });
  } catch (error) {
    console.log(error.message);
    dispatch({
      type: TICKETS_ERROR,
      payload: {
        msg: error.response.data.message,
        status: error.response.status,
      },
    });
    if (error.response.data.message === "Invalid Authentication") {
      console.log(error.response.data.message);
      dispatch({
        type: LOGOUT,
        payload: error.response.data,
      });
    }
  }
};

export const loadClosedTicketsEmp = () => async (dispatch) => {
  try {
    const response = await axios.get(URL + "/api/tickets/emp/mine/closed/");

    dispatch({ type: TICKETS_CLOSED, payload: response.data });
  } catch (error) {
    dispatch({
      type: TICKETS_ERROR,
      payload: {
        msg: error.response.data.message,
        status: error.response.status,
      },
    });
    if (error.response.data.message === "Invalid Authentication") {
      console.log(error.response.data.message);
      dispatch({
        type: LOGOUT,
        payload: error.response.data,
      });
    }
  }
};

export const addTicket =
  (toast, setLoad, data, navigate) => async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      await axios.post(URL + `/api/tickets/`, data, config);

      setLoad(false);
      toast.success("Ticket Submitted Successfully", {
        autoClose: 5000,
      });

      navigate("/user-dashboard");
    } catch (e) {
      setLoad(false);
      console.log(e);
      toast.error(e.response.data.message, {
        autoClose: 5000,
      });
      dispatch({
        type: TICKETS_ERROR,
        payload: e.response.data,
      });
      if (e.response.data.message === "Invalid Authentication") {
        console.log(e.response.data.message);
        dispatch({
          type: LOGOUT,
          payload: e.response.data,
        });
      }
    }
  };

export const addComment =
  (id, toast, setLoad, data, navigate, role) => async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      await axios.put(URL + `/api/tickets/comment/${id}`, data, config);

      toast.success("Comment added Successfully", {
        autoClose: 5000,
      });
      dispatch({ type: TICKETS_INIT_STATE });
      setLoad(false);
      if (role === "ADMIN") {
        navigate("/tickets-all");
      } else {
        navigate(-1);
      }
    } catch (e) {
      console.log(e);
      toast.error(e.response.data.message, {
        autoClose: 5000,
      });
      setLoad(false);
      dispatch({
        type: TICKETS_ERROR,
        payload: e.response.data,
      });
      if (e.response.data.message === "Invalid Authentication") {
        console.log(e.response.data.message);
        dispatch({
          type: LOGOUT,
          payload: e.response.data,
        });
      }
    }
  };

export const addAssignment =
  (id, toast, setLoad, data, navigate) => async (dispatch) => {
    try {
      const { category, priority, assignTo, deadline } = data;
      const body = JSON.stringify({
        category,
        priority,
        assignTo,
        deadline,
      });
      const config = {
        headers: {
          "Content-Type": "Application/json",
        },
      };

      await axios.put(
        URL + `/api/tickets/assignment-change/${id}`,
        body,
        config
      );

      toast.success("Ticket Updated Successfully", {
        autoClose: 5000,
      });
      dispatch({ type: TICKETS_INIT_STATE });
      setLoad(false);
      navigate(-1);
    } catch (e) {
      console.log(e);
      toast.error(e.response.data.message, {
        autoClose: 5000,
      });
      setLoad(false);
      dispatch({
        type: TICKETS_ERROR,
        payload: e.response.data,
      });
      if (e.response.data.message === "Invalid Authentication") {
        console.log(e.response.data.message);
        dispatch({
          type: LOGOUT,
          payload: e.response.data,
        });
      }
    }
  };

export const addStatus =
  (id, toast, setLoad, status, navigate) => async (dispatch) => {
    try {
      const body = JSON.stringify({
        status,
      });
      const config = {
        headers: {
          "Content-Type": "Application/json",
        },
      };

      await axios.put(URL + `/api/tickets/status/mark/${id}`, body, config);

      toast.success(
        `Ticket is ${
          status === "RESOLVED" ? "Resolved" : "Marked as Resolved"
        }`,
        {
          autoClose: 5000,
        }
      );
      dispatch({ type: TICKETS_INIT_STATE });
      setLoad(false);
      navigate(-1);
    } catch (e) {
      console.log(e);
      toast.error(e.response.data.message, {
        autoClose: 5000,
      });
      setLoad(false);
      dispatch({
        type: TICKETS_ERROR,
        payload: e.response.data,
      });
      if (e.response.data.message === "Invalid Authentication") {
        console.log(e.response.data.message);
        dispatch({
          type: LOGOUT,
          payload: e.response.data,
        });
      }
    }
  };

export const addEscalation =
  (id, toast, setLoad, navigate) => async (dispatch) => {
    try {
      if (
        window.confirm(
          "Are you sure you want to escalate this ticket to your superior?"
        )
      ) {
        const config = {
          headers: {
            "Content-Type": "Application/json",
          },
        };

        await axios.put(URL + `/api/tickets/status/escalate/${id}`, config);

        toast.success(`Ticket has been escalated to your superior`, {
          autoClose: 5000,
        });
        dispatch({ type: TICKETS_INIT_STATE });
        setLoad(false);
        navigate(-1);
      } else {
        setLoad(false);
      }
    } catch (e) {
      console.log(e);
      toast.error(e.response.data.message, {
        autoClose: 5000,
      });
      setLoad(false);
      dispatch({
        type: TICKETS_ERROR,
        payload: e.response.data,
      });
      if (e.response.data.message === "Invalid Authentication") {
        console.log(e.response.data.message);
        dispatch({
          type: LOGOUT,
          payload: e.response.data,
        });
      }
    }
  };

export const deleteMultipleTickets =
  (toast, setLoad, ticketIds, navigate) => async (dispatch) => {
    const body = JSON.stringify({
      ticketIds,
    });
    const config = {
      headers: {
        "Content-Type": "Application/json",
      },
      data: body,
    };
    try {
      const confirm = window.confirm(
        "Are you sure you want to remove these tickets permanently?"
      );
      if (confirm) {
        await axios.delete(URL + `/api/tickets/delete-multiple`, config);
        setLoad(false);
        toast.success("Selected Tickets Deleted Successfully", {
          autoClose: 5000,
        });
        dispatch({ type: TICKETS_INIT_STATE });
        navigate("/tickets-all");
      }
    } catch (error) {
      setLoad(false);
      toast.error(error.response.data.message, {
        autoClose: 5000,
      });
      dispatch({
        type: TICKETS_ERROR,
        payload: {
          msg: error.response.data.message,
          status: error.response.status,
        },
      });
      if (error.response.data.message === "Invalid Authentication") {
        console.log(error.response.data.message);
        dispatch({
          type: LOGOUT,
          payload: error.response.data,
        });
      }
    }
  };
