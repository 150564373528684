import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { format } from "date-fns";

import TopBar from "../../components/TopBar";
import Sidebar from "../../components/Sidebar";
import Spinner from "../../components/Spinner";
import Table from "../../components/Table";
import { loadDepartments } from "../../redux/tasks/departmentsT";

const TableColumns = [
  {
    Header: "Department Name",
    accessor: "departmentName",
  },
  {
    Header: "No of Categories",
    accessor: "categories",
    Cell: ({ value }) => {
      return value.length;
    },
  },
  {
    Header: "Created On",
    accessor: "createdAt",
    Cell: ({ value }) => {
      return format(new Date(value), "MM-dd-yyyy");
    },
  },
];

const Departments = ({ loading, departments, loadDepartments }) => {
  const navigate = useNavigate();
  const [numberOptions] = useState([5, 10, 15, 20, 25, 30]);
  const [globalFilter, setGlobalFilter] = useState("");

  useEffect(() => {
    loadDepartments(toast);
  }, [loadDepartments]);

  const handleData = (index) => {
    const dep = departments[index];
    navigate("/department-employees", { state: { dep } });
  };

  return loading ? (
    <Spinner />
  ) : (
    <>
      <div className="flex flex-col h-screen">
        <TopBar />
        <div className="flex flex-1 overflow-hidden">
          <Sidebar />
          <main className="flex-1 p-4 overflow-y-auto">
            <div className=" mx-auto">
              <div className="flex flex-col gap-x-6 sm:flex-row sm:items-center sm:justify-end space-y-2 sm:space-y-0 sm:space-x-2 p-4">
                {/* Add New Department Button */}
                <button
                  onClick={(e) => navigate("/department-add-new")}
                  className="bg-sysRed hover:bg-red-600 font-semibold text-white py-2 px-4 rounded sm:order-2"
                >
                  + Add New Department
                </button>

                {/* Search Field */}
                <div className="flex items-center border border-gray-300 rounded sm:order-1">
                  <input
                    type="text"
                    placeholder="Search"
                    className="p-2 w-full border-none outline-none"
                    value={globalFilter}
                    onChange={(e) => setGlobalFilter(e.target.value)}
                  />
                  <div className="p-2">
                    <FaSearch className="text-gray-500" />
                  </div>
                </div>
              </div>
              {departments?.length > 0 ? (
                <Table
                  data={departments}
                  columns={TableColumns}
                  numberOptions={numberOptions}
                  dataFun={handleData}
                  globalFilter={globalFilter}
                  setGlobalFilter={setGlobalFilter}
                />
              ) : (
                <></>
              )}
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

Departments.propTypes = {
  loading: PropTypes.bool.isRequired,
  departments: PropTypes.array,
  loadDepartments: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.departments.loading,
  departments: state.departments.departments,
});

export default connect(mapStateToProps, { loadDepartments })(Departments);
